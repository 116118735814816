import React from 'react'

export default function NotFound() {
    return (
        <div>
            <div className="py-64 text-center text-3xl">
                Page 404
            </div>
        </div>
    )
}
