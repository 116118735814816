import React from 'react'
import wave1 from '../assets/images/wave1.png';
import wave2 from '../assets/images/wave2.png';

export default function tos() {
    return (
        <div>
            <div className="container mx-auto pt-44">
            <div className="w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 absolute top-0 right-0" style={{zIndex: -10}}>
                <img src={wave1} alt="Wave one" draggable="false" />
            </div>
            <div className="absolute bottom-40 -left-1" style={{zIndex: -10}}>
                <img src={wave2} alt="Wave one" draggable="false" />
            </div>
                <div className="w-full lg:w-6/12 mx-auto px-8 lg:px-8 mb-40">
                    <h5 className="text-sm tracking-wide text-green font-bold">LAST MODIFIED ON 14 JULY 2020</h5>
                    <h2 className="text-3xl lg:text-4xl max-w-lg leading-snug font-bold">
                        HaloDesk Terms of Service and Subscription Agreement
                    </h2>
                    <div className="my-8">
                        <p className="text-justify leading-relaxed text-gray-400 max-w-2xl mb-4">
                            Please read these Terms of Service (this “Agreement”) fully and carefully before using HaloDesk Sales Solutions. This Agreement sets forth the legally-binding terms and conditions for your use of HaloDesk Sales Solutions. By registering for and/or using the services in any manner, you agree that you have read, understand and accept this agreement and all other operating rules, policies and procedures referenced herein, each of which is incorporated herein by this reference and each of which may be updated from time to time as set forth below, to the exclusion of all other terms. You may not use the services if you do not unconditionally accept this Agreement. If you are accepting on behalf of an organization, you represent and warrant that you have the authority to do so; however, if your organization has entered into a separate contract with HaloDesk Pte Ltd covering its use of the Services, then that contract shall govern instead.
                        </p>
                        <p className="text-justify leading-relaxed text-gray-400 max-w-2xl mb-4">
                            Except for certain types of disputes defined in the arbitration section below, you agree that disputes between you and HaloDesk Pte Ltd shall be resolved by binding, individual arbitration and you waive your right to participate in a class action lawsuit or class-wide arbitration. 
                        </p>
                        <p className="text-justify leading-relaxed text-gray-400 max-w-2xl mb-4">
                            HaloDesk may change the Terms of Service from time to time at its sole discretion by posting a revised version of the Terms of Service on the HaloDesk website, without particular notice to you. The modified terms will become effective immediately upon posting. By continuing to use HaloDesk on and/or after the effective date of any modifications to these Terms of Service, you agree to be bound by the modified terms.
                        </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            1. DEFINITIONS
                        </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“Client”</span> shall refer to you, unless you are accepting on behalf of a company, in which case “Customer” shall mean that organization;</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“HaloDesk Pte Ltd”</span> shall refer to a corporation with its principal place of business at 59 Ubi Avenue 1, #04-07, Singapore 408938, that is the service provider of HaloDesk Sales Solutions;</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“HaloDesk Pte Ltd”</span> shall refer to a corporation with its principal place of business at 59 Ubi Avenue 1, #04-07, Singapore 408938, that is the service provider of HaloDesk Sales Solutions;</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“HaloDesk”</span> shall refer to the Sales Solutions provided by HaloDesk Pte Ltd and the underlying servers and software used to provide such solutions;</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“HaloDesk Pte Ltd” and “Clients”</span> are each referred to in this Agreement as a “Party” and collectively as “Parties.”</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“User Data”</span> shall refer to any personally identifiable information input by the Client to HaloDesk, including information possibly considered private or sensitive generated by the Client and all other information provided in the normal course of usage of HaloDesk.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“PDPA”</span> means the Personal Data Protection Act 2012;.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“Personal Data”</span>shall refer to data, whether true or not, about an individual who can be identified:</p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 text-justify leading-relaxed text-gray-400">from that data alone; or</li>
                            <li className="my-3 text-justify leading-relaxed text-gray-400">from that data and other information which HaloDesk has or is likely to have access;</li>
                        </ul>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“Subscription”</span> shall refer to the right to access HaloDesk during the Subscription Period.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“Subscription Period”</span> shall refer to the duration that the Client has access to the HaloDesk.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">“Order Form”</span> shall refer to any contracts and/or documents that are entered into, by the Client and HaloDesk from time to time, in the forms provided by HaloDesk.</p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            2. PROVISION OF SERVICE
                        </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Free Trial.</span> HaloDesk may make all or part of HaloDesk available to the Client on a trial basis, free of charge (the “Free Trial”). The Free Trial shall begin when the Client submit a registration for the same to HaloDesk, and shall terminate on the earlier of (i) the Free Trial expiration date as specified by HaloDesk in the Client’s HaloDesk account settings, or (ii) the date the Client execute an Order Form for a Subscription under this Agreement. Notwithstanding section 14, access to HaloDesk is provided “as-is” and without warranty of any kind during Free Trial. User Data entered and any specific settings or preferences the Client makes during Free Trial will be permanently deleted unless the Client purchases a Subscription or export such data/settings prior to the expiration of their Free Trial. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Mobile Applications.</span> HaloDesk may offer mobile applications that allow the Client to access HaloDesk on their mobile devices, including, but not limited to devices running Android or iOS operating systems. Mobile devices are not included in the Client’s Subscription. HaloDesk mobile applications require an active internet connection, and may incur data charges with the Client’s wireless carrier, including roaming charges, where applicable. HaloDesk mobile applications may collect technical data, including information about application crashes and usage statistics, and may use certain third party libraries or modules. Any such mobile applications offered by HaloDesk shall constitute part of HaloDesk Sales Solutions, and as such their usage is subject to the terms and conditions of this Agreement. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Subscription.</span> HaloDesk shall be available to the Client pursuant to this Agreement and all Order Forms during the Subscription Period. The Client shall agree that the purchase of HaloDesk Subscription is neither contingent upon the delivery of any future functionality or features, nor dependent upon any oral or written public comments made by HaloDesk with regard to future functionality or features. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Support.</span> Support via in-app intercom widget, email and phone is available for paid Subscription. Our Support hours are Monday to Friday (exclude Singapore public holidays), from 10am to 6pm. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            3. USAGE GUIDELINES
                        </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Ownership of Data.</span> HaloDesk The Client shall retain all right, title and interest in and to all of their data. HaloDesk shall not access the Client’s Data except to respond to service or technical problems, or at the Client’s request.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Collection of Data.</span> The Client shall be responsible for all activities in their account and in compliance with this Agreement. The Client shall also comply with all local and foreign law, treaty, regulation or convention applicable in connection with the use of HaloDesk, including without restriction, the Personal Data Protection Act 2012 (Singapore), and other laws applicable related to privacy, publicity, data protection, electronic communications and anti-spamming laws. The Client shall be responsible for the collection, legality, protection and use of data that is stored on, or used in connection with HaloDesk. HaloDesk will not be responsible for any loss or disclosure of the Client’s data (or any damages related thereto) resulting from the Client’s failure to adequately secure their user identification and passwords..</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Prohibited Uses.</span> The Client shall hereby agree that as a condition of use of HaloDesk, not to use HaloDesk for any purpose that is unlawful or prohibited by these terms, conditions, and notices. The Client may not use HaloDesk in any manner that could damage, disable, overburden, or impair any HaloDesk or subscriber server, or the network(s) connected to any service provider or subscriber server, or interfere with any other party's use and enjoyment of HaloDesk.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client may not attempt to gain unauthorized access to any part of HaloDesk, other accounts, computer systems or networks connected to HaloDesk, through hacking, password mining or any other means. The Client may not obtain or attempt to obtain any materials or information through any means not intentionally made available through HaloDesk. Except as expressly set forth herein, the Client may not (i) copy, reproduce, alter, modify, transmit, perform, create derivative works of, publish, sub-license, distribute, or circulate HaloDesk, or any associated applications, tools or data thereof; (ii) disassemble, decompile, or reverse engineer the software used to provide HaloDesk; or (iii) take any actions, whether intentional or unintentional, that may circumvent, disable, damage or impair HaloDesk's control or security systems, or allow or assist a third party to do so.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client shall be responsible for the content of all communications sent through HaloDesk, and shall comply with all applicable laws and regulations while using HaloDesk. The Client may not use HaloDesk for illegal purposes or for the transmission of material that is (i) unlawful, harassing, libellous, invasive of another's privacy, abusive, threatening, harmful, vulgar, obscene, tortuous, or otherwise objectionable; (ii) infringes or may infringe upon the Intellectual Property Rights or other rights of HaloDesk or any other Party; or (iii) would otherwise give rise to civil liability, or that constitutes or encourages conduct that could constitute a criminal offense under any applicable law or regulation. The Client shall further agree to not use HaloDesk for the purpose of making emergency calls or providing emergency services.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Suspension of Service.</span> HaloDesk may at any time suspend (or require the Client to suspend) access of HaloDesk and/or disable the login information in the event of violation of these terms and conditions. Grounds for doing so are not limited but may include, for example, legal or regulatory reasons, investigation of suspicious activities, or action by authorities, or if HaloDesk or the Client may have reason to suspect any such Client is engaged in activities that may violate these Terms of Service, applicable laws, or subscriber policies, or are otherwise deemed harmful to service provider, the Client and respective network or facilities, or other Clients. HaloDesk, and any of its service providers shall not be liable to any Client for suspension of SaaS Service, regardless of the grounds.</p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            4. THIRD PARTY PROVIDERS
                        </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Acquisition of Non-HaloDesk Products and Services.</span> The Client shall acknowledge that third-party products or services may be made available to the Client from time to time by HaloDesk or third parties, and that the Client’s decision to acquire any such products or services is solely between the Client and the applicable third party provider. Unless specifically set forth on an Order Form, HaloDesk does not warrant or support third-party products or services.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Third-Party Services and User Data.</span> The Client shall acknowledge that if the Client acquires third-party services for use with Subscription, HaloDesk may allow the providers of such applications to access the Client’s data in order to allow such applications to interoperate with HaloDesk. HaloDesk shall not be responsible or liable for any disclosure, modification or deletion of the Client’s data resulting from any such access by third-party application providers..</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Potential damages arising from usage of Third-Party Service Integrations.</span> The Client shall further acknowledge that if the Client uses third-party services intraoperatively with HaloDesk, HaloDesk shall not be responsible or liable for any damages arising from the failure of such third-party applications to perform its intended functions. Any claims to such damages suffered by the Client resulting from the failure of third-party services to function are to be taken up with the service providers of such third-party services.</p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            5. CLIENT OBLIGATIONS
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">To access and use HaloDesk, the Client must create account(s).</p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 leading-relaxed text-justify">The Client agrees to provide accurate, complete and current account information and, as applicable, timely update the same. The Client is solely responsible for the activities that occur on their account(s) (which includes its sub-accounts), and for keeping their account(s) passwords secure. HaloDesk will not be liable for any losses caused by unauthorized use of the Account;</li>
                            <li className="my-3 leading-relaxed text-justify">If any information provided by the Client is inaccurate, incomplete and/or not current, HaloDesk has the right to terminate the Client’s account(s) and refuse any and all current or future use of HaloDesk by the Client;</li>
                            <li className="my-3 leading-relaxed text-justify">The Client represents and warrants that it is duly authorized to do business and use HaloDesk in all jurisdictions in which the Client operates in. The Client must notify HaloDesk prompt of any change in the Client’s eligibility to use HaloDesk (including any changes to or revocation of any licenses from state authorities), breach of security of any known authorized use of the Client’s account(s).</li>
                            <li className="my-3 leading-relaxed text-justify">The Client agrees to be solely responsible for any and all charges incurred due to the Client’s use of a local access number in relation to their usage of HaloDesk’s account(s); for any and all charges incurred due to the Client’s use of any wireless device including, but not limited to laptops, tablet computers, and cellular phones; for all billing transactions: Billing transactions encompass sign-up transactions (purchases at the time of sign-up), purchase transactions (purchases that are not auto-recharge transactions which are made after sign-up) and auto-recharge transactions (purchases that are automatically made via HaloDesk’ auto-recharge feature); for all content created by the Client in their course of usage of HaloDesk Sales Solutions, including but not limited to textual information, video or static imagery, and audio recordings;</li>
                            <li className="my-3 leading-relaxed text-justify">The Client shall immediately notify HaloDesk at contact@HaloDesk.io of any unauthorized use of the Client's HaloDesk account(s) or any other breach of security. Until the Client makes the appropriate notification, the Client shall be presumed liable for any and all charges incurred under their HaloDesk account(s).</li>
                        </ul>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            6. USER DATA
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">The use of User Data is governed by our Privacy Policy. HaloDesk will not disclose billing information or account access information except as authorised by the Client or the Client's representative or as required by any applicable laws or legal process.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">HaloDesk implements industry standards to ensure the security and confidentiality of User Data, protect against anticipated threats or hazards to the security or integrity of User Data and protect against unauthorised access to or use of this Data.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Compliance with PDPA.</span> In handling any Client Personal Data, HaloDesk shall comply with all its obligations under the PDPA and in this Clause 6 at its own cost.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Process, Use and Disclosure.</span></p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">HaloDesk only process the Client’s Personal Data:</p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 leading-relaxed text-justify">strictly for the purposes of fulfilling its obligations and providing the Services required under this Agreement;</li>
                            <li className="my-3 leading-relaxed text-justify">with the Client’s prior written consent; or</li>
                            <li className="my-3 leading-relaxed text-justify">when required by law or an order of court, but shall notify the Client as soon as practicable before complying with such law or order of court at its own costs.</li>
                        </ul>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Transfer of personal data outside Singapore.</span></p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">HaloDesk may transfer Client Personal Data to only its own HaloDesk Sales Solutions servers located in countries other than the country in which the Client Personal Data was originally supplied by the Client and strictly only for the purposes of fulfilling its obligations and providing the Services required under this Agreement. In respect of such transfers, the Supplier shall ensure that the Client Personal Data transferred outside Singapore will be protected at a standard that is comparable to that under the PDPA and this Agreement.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Security Measures.</span>HaloDesk shall protect Client Personal Data in HaloDesk’s control or possession by making reasonable security arrangements (including, where appropriate, physical, administrative, procedural and information & communications technology measures) to prevent unauthorised or accidental access, collection, use, disclosure, copying, modification, disposal or destruction of Client Personal Data, or other similar risks. For the purposes of this Agreement, “reasonable security arrangements” include arrangements set out below in this Clause 6 (which shall not be varied without the Client’s prior written consent, such consent not to be unreasonably withheld).</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Accuracy and Correction of Personal Data.</span> Where the Client provides Client Personal Data to the HaloDesk, the Client shall make reasonable effort to ensure that the Client Personal Data is accurate and complete before providing the same to HaloDesk. The Client shall remain responsible for updating Client Personal Data which is in the possession of the Supplier. The Supplier shall take steps to correct any errors in the Client Personal Data, as soon as practicable upon the Client’s written request.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Retention of Personal Data:</span></p>
                        <p className="my-6 text-justify leading-relaxed text-gray-400">The Supplier shall not retain Client Personal Data (or any documents or records containing Client Personal Data, electronic or otherwise) for any period of time longer than is necessary to serve the purposes of this Agreement.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Supplier shall, upon the request of the Client:</p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 leading-relaxed text-justify">return to the Client, all Client Personal Data; or</li>
                            <li className="my-3 leading-relaxed text-justify">delete all Client Personal Data in its possession,</li>
                            <li className="my-3 leading-relaxed text-justify">and, after returning or deleting all Client Personal Data, provide the Client with written confirmation that it no longer possesses any Client Personal Data. Where applicable, the Supplier shall also instruct all third parties to whom it has disclosed Client Personal Data for the purposes of this Agreement to return to HaloDesk or delete, such Client Personal Data.</li>
                        </ul>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            7. UNUSED BALANCE
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">HaloDesk reserves all rights on accounts that are expired. Unused credit balance in an expired HaloDesk account cannot be cashed out, and shall be forfeited.</p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client acknowledges and accepts that the inherent nature of the Internet and/or telecommunication network is such that electronic communications via email or SMS may be subject to interruption, interception, hacking, transmission blackout, and delayed or incorrect transmission. HaloDesk shall not be liable for any risks including but not limited to risks resulting from errors, mutilation, interruption or delay in transmission, power failure, interception by third parties, data corruption, viruses, transmission errors, breakdown of telecommunication networks, malfunctions in communications facilities, industrial action or disputes beyond its control that may affect the transmission of any email and/or SMS and does not warrant that any identified defect will be corrected, though it will undertake all reasonable efforts to mitigate and rectify such risks.</p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            8. PAYMENT AND CHARGES
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">In order to use the integrated calling and SMS tools on HaloDesk, the Client will require a sufficient credit balance under their HaloDesk account(s) that is separate from and not included as part of HaloDesk account subscription fees. The Client is required to deposit sufficient credit in their HaloDesk account through debit or credit cards, PayPal or other means, which may be made available by HaloDesk. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client agrees to pay for its use of HaloDesk as set forth in any written agreement or addendum with HaloDesk Pte Ltd, and/or its partners. HaloDesk Pte Ltd reserves the right to revise the pricing from time to time and would give notice of such change on HaloDesk website. Client’s continued use of HaloDesk Sales Solutions after a price change becomes effective constitutes the Client’s agreement to pay the newly revised amount. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">In the event the Client believes that HaloDesk has charged them erroneously, the Client must notify HaloDesk via email at contact@HaloDesk.io within seven (7) business days after such charge. Upon expiration of such 30-day period, the Client will not be entitled to dispute any fees paid or payable to HaloDesk. The Parties will work together in good faith to resolve billing disputes. A pending billing dispute shall not exempt the Client from timely paying any undisputed amounts owed. Any disputes that cannot be resolved in accordance with the foregoing shall be handled in accordance with Section 13 below. Other than in accordance with the foregoing, fees paid are non-refundable. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            9. AUTOMATICALLY RECURRING SUBSCRIPTIONS
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">If the Client opts for an automatically recurring subscription at the point of purchase, the Client unconditionally consents to HaloDesk and/or its payment partners storing personal information about the Client, including information on the Client’s credit card. HaloDesk and its partner(s) will make all reasonable efforts, according to standard industry practice, to secure the Client’s personal information. In addition, the Client also unconditionally consents HaloDesk and/or its payment partner making deductions automatically from the Client’s stored credit card for the purpose of renewing the Client’s HaloDesk Subscription at the end of each Subscription Period. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            10. INTELLECTUAL PROPERTY RIGHTS
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">The Client shall acknowledge and accept that all title, ownership and intellectual property rights in and to HaloDesk and any and all copies thereof, are owned by HaloDesk and/or its or its affiliates’ licensors. All rights are reserved. HaloDesk is protected by copyright laws, international copyright treaties and conventions and other laws. HaloDesk contains certain licensed materials and HaloDesk’ and its affiliates’ licensors may protect their rights in the event of any violation of these Terms of Service. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            11. INDEMNITY
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">The Client hereby agrees to indemnify and hold HaloDesk, and its subsidiaries, affiliates, officers, and employees, harmless from any and all liability, claim or demands, including reasonable attorney's fees, made by any third party because of or arising out of the Client's use of HaloDesk and the Client's violation of these Terms, any applicable law or regulation, or the rights of any third parties related to the use of the HaloDesk. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            12. WARRANTIES
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">The Client expressly agrees that the use of HaloDesk is at the Client’s sole risk. HaloDesk is provided on an "as is" and "as available" basis. HaloDesk expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. HaloDesk makes no warranty that HaloDesk will meet the Client's requirements, that it will be uninterrupted, timely, secure, or error free; nor does HaloDesk make any warranty as to the results that may be obtained from the use of HaloDesk or as to the accuracy or reliability of any information obtained through HaloDesk or that defects in HaloDesk will be corrected. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">Neither HaloDesk nor any of its information providers makes any warranties concerning the accuracy, currentness or reliability of the messages received or requested via the HaloDesk or any of the information, material or content contained therein (the "Content"). The Client understands and agrees that any Content and/or data downloaded or otherwise obtained through the use of or from HaloDesk is done at the Client's own discretion and risk and that the Client will be solely responsible for any damage to the Client's mobile telecommunication device and/or computer system or loss of data that results from the download of such Content and/or data. HaloDesk makes no warranty and shall not be liable for any goods, services, information or other material displayed, purchased or obtained through or from HaloDesk or any transaction entered into through the service. HaloDesk shall not be liable for any delays, omissions or errors in the Content and shall not be liable for any reliance thereon. No advice or information, whether oral or written, obtained by the Client from HaloDesk or through or from HaloDesk shall create any warranty not expressly stated herein. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            13. LIMITATION OF LIABILITY
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">In no event shall HaloDesk, its subsidiaries, affiliates, officers, or employees be liable, whether in contract, warranty, tort (including negligence) or any other form of liability, for:</p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 leading-relaxed text-justify">any direct, indirect, incidental, special or consequential damages suffered by the Client; and</li>
                            <li className="my-3 leading-relaxed text-justify">any loss of income, business, profits (whether direct or indirect), use, data or other intangible suffered by the Client, even if HaloDesk has been advised of the possibility of such damages.</li>
                        </ul>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">Without limiting the above clauses [13.i, 13.ii], HaloDesk, its subsidiaries, affiliates, officers, or employees shall not be liable for any losses suffered by the User arising from: </p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 leading-relaxed text-justify">the use or the inability to use HaloDesk;</li>
                            <li className="my-3 leading-relaxed text-justify">messages received or transactions entered into through or from HaloDesk;</li>
                            <li className="my-3 leading-relaxed text-justify">unauthorized access to or alteration of the Client's transmissions or data;</li>
                            <li className="my-3 leading-relaxed text-justify">the Client’s inability to use HaloDesk to contact emergency services;</li>
                            <li className="my-3 leading-relaxed text-justify">any degradation in the quality of HaloDesk (including but not limited to, degradation in the quality of the calls made through HaloDesk); or</li>
                            <li className="my-3 leading-relaxed text-justify">any breakdown in the payment systems utilised by the Client for the top-up of credit in the Client’s HaloDesk account(s).</li>
                        </ul>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client further agrees that HaloDesk shall not be liable for any damages arising from interruption, suspension or termination of HaloDesk, including but not limited to direct, indirect, incidental, special, consequential or exemplary damages, whether such interruption, suspension or termination was justified or not, negligent or intentional, inadvertent or advertent. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">HaloDesk expressly disclaims any and/or all liability for inbound mobile phone roaming charges, mobile data charges and/or other like or unlike third party charges incurred by the Client as a result of the use of HaloDesk from a mobile device while it is roaming. For the avoidance of doubt, and without limiting the generality of the foregoing, HaloDesk shall not be liable for any mobile phone roaming charges or mobile data charges incurred by the Client should the User fail to properly launch or access HaloDesk. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">Nothing in these Terms of Service is intended to exclude or restrict or shall be construed as excluding or restricting the liability of HaloDesk for any liability which cannot be limited or excluded by law. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            14. TERM AND TERMINATION
                        </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Term of the Agreement.</span> This Agreement commences on the effective date and continues until the expiration or termination of the Client’s Free Trial or Subscription (as applicable). </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Termination by Client.</span> The Client may cancel their Subscription and terminate this Agreement at any time by providing notice to HaloDesk via email at contact@HaloDesk.io. Upon account termination, HaloDesk will allow a 30-day grace period during which the Client will be able to reactivate their account and restore their data. In the case that the Client wishes to have their data completely and permanently removed from HaloDesk's application servers, the Client can inform HaloDesk in writing by sending an email to contact@HaloDesk.io. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Termination for Cause.</span> Without limiting other remedies, HaloDesk may terminate the Client's HaloDesk account(s) with immediate effect, automatically and without recourse to the courts, and may limit, suspend or terminate the Client’s use of the HaloDesk, if HaloDesk finds that the Client is: </p>
                        <ul className="list-disc text-sm text-gray-400 pl-8 mt-2 mb-6">
                            <li className="my-3 leading-relaxed text-justify">in breach of these Terms of Service;</li>
                            <li className="my-3 leading-relaxed text-justify">creating problems or possible legal liabilities;</li>
                            <li className="my-3 leading-relaxed text-justify">acting inconsistently with the letter or spirit of HaloDesk’s policies;</li>
                            <li className="my-3 leading-relaxed text-justify">infringing intellectual property rights;</li>
                            <li className="my-3 leading-relaxed text-justify">and/or engaging in fraudulent, immoral or illegal activities.</li>
                        </ul>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            15. TAXES
                        </p>
                        <p className="my-1 text-justify leading-relaxed text-gray-400">Unless otherwise stated, all charges and fees for HaloDesk Sales Solutions are exclusive of any country, province, federal, state or local taxes, including without limitation, use, sales, value-added, privilege, or other taxes, levies, imports, duties, fees, surcharges, governmental assessments and withholdings ("Taxes"). </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client will be solely liable for and will pay upon demand all Taxes associated with the Client's access to and use of the Services and shall not deduct any such amounts, or any other withholdings, set-offs or deductions. </p>
                    </div>
                    <div className="my-8">
                        <p className="text-xs font-bold text-green">
                            16. GENERAL
                        </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Terms of Service constitute the entire Agreement between the Client and HaloDesk with respect to the subject matter hereof and will supersede and replace all prior understandings and agreements, in whatever form, regarding the subject matter. Nothing in this clause shall exclude or restrict the liability of the Client arising out of fraud or fraudulent misrepresentation. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The failure to exercise, or delay in exercising, a right, power or remedy provided by the Terms of Service or by law shall not constitute a waiver of that right, power or remedy. If HaloDesk waives a breach of any provision of the Terms of Service this shall not operate as a waiver of a subsequent breach of that provision, or as a waiver of a breach of any other provision. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms of Service remain in full force and effect. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400">The Client agrees that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the HaloDesk must be filed by the Client within one year after such claim or cause of action arose or be forever barred. </p>
                        <p className="my-3 text-justify leading-relaxed text-gray-400"><span className="font-bold">Governing Law.</span> These Terms of Service will be governed by and construed in accordance with the laws of Singapore, and the courts of Singapore will have non-exclusive jurisdiction over any claim or dispute arising under or in connection with these Terms of Service.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
