import React, { useState } from 'react'
import wave1 from '../assets/images/wave1.png';
import {Link} from 'react-router-dom';
import Logo from '../assets/images/logo_halodesk.png';
import { GoogleSpreadsheet } from "google-spreadsheet";
import Modal from 'react-modal';


// Config variables


export default function Contact() {

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const SPREADSHEET_ID = '1E-duqWHAHeIw2sdTpNpy5WlbOMUJXnACVTGe3FwW1AY';
    const SHEET_ID =0;
    const CLIENT_EMAIL = 'halodesk-web@halodesk.iam.gserviceaccount.com';
    const PRIVATE_KEY = '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCFS6ikingv6EPS\n2ydFDfgLPUSxgSFfhB750oa88BoJM0sNNFCXYaqvb0BfPqK2v90wbkw1h2odnSDr\n5OYjDEjwYhxwXJfB/m2lmzHG1anmMtyWAYOkH25vW3jmYbclOP6mH9/GOqXSagTa\n83wJ21tZ8BqlMwD7ujqIHdw5a8kd3L3nLUs80XCDKJSJi1JtFK9tHWUN/9ykslfL\njWiJjYTdN5aMpevgiVxAXUvV3lcE2w+NfVxAUEZvQuvXKyTMAkV9YmUr0R120eLu\nOMuuh89sBmSoiKjWMlBt01JN8Pcnx7ld4X/lJ77BUIZ1smKtfdO/k+jVk57QAMAs\nb1xWON1HAgMBAAECggEAAtZYcaAs7MAh6XQWwuaIOB0GwEyCXFkgm6q6iX0G4NlB\nqLdLW92NV4yjryNTLVaqK6S9ZIpMXPMCR2Mo7ugNCWjdOBsQc+WpidPi3Fuh8S91\n+I9xsOWmYSJuUWEVF+q9qOWWys5mxzLQhaSXKFKOAD0XJBsQ00rkgg84p9Q+hK8t\nUkMV3d4efRQZyQRnnBu83H6L16KbKIqUKFU6gScWj9TRVsdIpk/j2b4f+InVxwdC\nyJagXsGmH4b2HiOpYMiLn6aAV5q4qczSpFYzA0d5ldjkfFNTp90O888BvQ1PPDzb\n9MBiZ0/uL89u8qZL+cHuiMvtY2hI9px7DrPHaF8EWQKBgQC7Eo11cY9X1A/VoDRm\nbktJwTARr29O4nb/GKsnEcAxpNFLnpqQKYUHkhNOOXmJoLzyasItSVobb4s/0yDB\nl/ykFWi+3tmyQ7e1fdyO0lOyoY/g6Njg21Cco7BLvr4XRw70W4wsPLnEh5+LiyzV\nCejl/7b8B+axYErKF7ShKzY16wKBgQC2aKTzKiMEdhmBOsjEoSGsGQrPNQIvlvia\naZdpllcDCkStu9TGxuIq6EiTPETQKzjB9SUT6wefrjjuD9NsHRjTGiSXyKRsBYB/\nEp1SD1Bl9AhYlVRlNyBQWaav7j/Z562KLEuSeZKWoCk5qYNrfuVv6OKUePPg+f9t\nVJ8vHtITFQKBgGHij0pIpWybdb2eGk0OoS5ye6iFV4S7UvCgA85pp1tSloEu+Ek+\nsHEJqjhgMowlIzjlS71OiGuarg4zdwxVbsicuzZEfTyd2l6JAQNqYokxqItSy0i8\n+S09KV447FZZxCVwUaREEiAXpw/pPwgqK0dNyn2N1NQzQm8EgGtkpPUJAoGAbYzr\nhB8KaOOE7dggO/eKAZIIq+lqJqrFI/fGt+NS3sCL/suQPtGzg5F+BO9tZJiQvew9\nGHlPWteH2XInyAaxohdRYtdd6IJlaewEEASihJjLamhFB4TUI220bnToZDaAQzvX\nJ2FcaJpIKBlmlGjh4xQ7AHCkB7odbcwkzmf0QwECgYEAuE0Y7iy18l+gw6+E3EdW\nsrNPhmKxdZ8xQj7ZUbwbA1NToGEni3LtiwTtJUjtfe+BvJ7JknI4ysf6RXuGCoKY\nE4jGlLzBN5PNc570+bcyZZc3MvlpO+fEtq3bn+GztnbkvTX3YVgchIJq3upPZsGA\nNQvdAYMmgP6c+aGll3g7qYo=\n-----END PRIVATE KEY-----\n';

    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const appendSpreadsheet = async () => {
    try {
        await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
        });
        // loads document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsById[SHEET_ID];
        let row = { 
            FirstName: document.getElementById('first_name').value ,
            LastName: document.getElementById('last_name').value ,
            CompanyName: document.getElementById('company_name').value ,
            Phone: document.getElementById('phone').value ,
            Email: document.getElementById('email').value ,
        }
            
        await sheet.addRow(row);
        handleShow()
        setTimeout(()=> {
            handleClose()
            document.getElementById('first_name').value = ""
            document.getElementById('last_name').value = ""
            document.getElementById('company_name').value = ""
            document.getElementById('phone').value = ""
            document.getElementById('email').value = ""
        },3000)
        // console.log("RES " , result)
    } catch (e) {
        console.error('Error: ', e);
    }
    };

    // const newRow = { Name: "new name", Value: "new value" };

    return (
        
        <div>
           <Modal
        isOpen={show}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>Thank you for contacting us. We will contact you soon</div>
      </Modal>

            <div className="container items-center mx-auto flex flex-col lg:flex-row px-8 mb-40 lg:px-24 pt-44">
                <div className="w-8/12 md:w-6/12 lg:w-4/12 xl:w-5/12 absolute top-0 right-0" style={{zIndex: -10}}>
                    {/* <img src={wave1} alt="Wave one" draggable="false" /> */}
                </div>
                <div className="contact w-full lg:w-1/2 px-0 lg:px-8 mx-auto">
                    <div className="">
                        <h2 className="text-3xl lg:text-4xl font-bold">
                            Lets Contact us for <span className="text-underline-bold">Experience</span>
                        </h2>
                        <p className="text-sm lg:text-base text-gray-400 mt-4">
                            Fill your data for get experience with HaloDesk. Enjoy testing every feature from our All-Inclusive plan starting today.
                        </p>
                        <div className="badge mt-4 inline-flex">
                            <div className="badge-item px-2 text-center text-white py-1 text-xs bg-green-600 rounded-xl w-28 mr-2">
                                14 Day Trials
                            </div>
                            <div className="badge-item px-2 text-center text-white py-1 text-xs bg-yellow-400 rounded-xl w-44">
                                No Credit Card required
                            </div>
                        </div>
                    </div>
                    <div className="contact__steps py-6 w-full bg-white rounded-2xl">
                        <div className="contact__form my-6">
                            <div className=''>
                                <div className="contact__form_control">
                                    <label htmlFor="first_name" className="contact__form_label font-bold text-lg">First Name <span className="text-red-400">*</span></label>
                                    <input type="text" id="first_name" className="form_input_helpdesk" />
                                </div>
                                <div className="contact__form_control">
                                    <label htmlFor="last_name" className="contact__form_label font-bold text-lg">Last Name <span className="text-red-400">*</span></label>
                                    <input type="text" id="last_name" className="form_input_helpdesk" />
                                </div>
                                <div className="contact__form_control">
                                    <label htmlFor="company_name" className="contact__form_label font-bold text-lg">Company Name <span className="text-red-400">*</span></label>
                                    <input type="text" id="company_name" className="form_input_helpdesk" />
                                </div>
                                <div className="contact__form_control">
                                    <label htmlFor="phone" className="contact__form_label font-bold text-lg">Phone <span className="text-red-400">*</span></label>
                                    <input type="text" id="phone" className="form_input_helpdesk" />
                                </div>
                                <div className="contact__form_control">
                                    <label htmlFor="email" className="contact__form_label font-bold text-lg">Email <span className="text-red-400">*</span></label>
                                    <input type="email" id="email" className="form_input_helpdesk" />
                                </div>
                                <div className="border-b border-dotted border-gray-100"></div>
                                <div className="flex items-center my-4">
                                    <input type="checkbox" className="form_input_helpdesk" id="information"/>
                                    <label htmlFor="information" className="contact__form_label text-sm ml-2 text-gray-400 cursor-pointer">Send me Updated information of HaloDesk</label>
                                </div>
                            </div>
                            <div className="my-4">
                                <button onClick={appendSpreadsheet} className="bg-green hover:bg-green-600 transition-colors duration-500 px-6 py-4 text-white rounded-md w-full">Contact Now</button>
                                <p className="my-2 text-sm text-gray-400">By Contact Us, I Accept <Link to="/term-of-services" className="link_hover_border_bottom font-semibold">Term Of Service</Link> & <Link to="/privacy-policy" className="link_hover_border_bottom font-semibold">Privacy Policy</Link></p>
                            </div>
                            <div className="mt-14 flex items-center justify-around">
                                {/* <img src={Logo} alt="Logo Halodesk" className="w-28 mr-3"/>
                                <img src={Logo} alt="Logo Halodesk" className="w-28 mx-3"/> */}
                                <img src={Logo} alt="Logo Halodesk" className="w-28 mx-3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
